<template>
  <div class="col-12">
    <div class="justify-content-between d-flex">
      <h2>Zařazení do rozpočtu</h2>
    </div>
    <div class="pb-4">
      <DocumentDetailRow v-if="budgets.length === 0" header="Nezapočítává se do rozpočtu"
          :body="'-'"
        />
      <template v-if="$store.getters.hasPerm('view_budget')" v-for="budget in budgets">
        <DocumentDetailRow :header="`${budget.region.name}`"
          :body="`${budget.amount} Kč / ${budget.paid_at}`"
        />
      </template>
    </div>
  </div>
</template>


<script>
import DocumentDetailRow from '@/components/DocumentDetailRow.vue';

export default {
  emits: ['reload-data'],
  components: {
    DocumentDetailRow,
  },
  props: {
    documentId: {
      required: true
    }
  },
  data() {
    return {
      budgets: [],
    }
  },
  created: function () {
    this.loadData();
  },
  methods: {
    loadData: function () {
      this.$store.getters.api.get("/finance/" + this.documentId + "/budgets").then(function (response) {
        this.budgets = response.data.items;
      }.bind(this));
    }
  }
}
</script>


<style scoped lang="scss">
h2 {
  font-size: 24px;
}

.row {
    border-bottom: 1px solid #ccc;
  }
</style>
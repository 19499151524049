import { createRouter, createWebHistory } from 'vue-router';

import { CONFIG } from "@/config.js"
import store from '@/store.js'

import Dashboard from '@/views/Dashboard.vue';
import Settings from '@/views/Settings.vue';
import Members from '@/views/Members.vue';
import Member from '@/views/Member.vue';
import Finances from '@/views/Finances.vue';
import FinanceDetail from '@/views/FinanceDetail.vue';
import FinanceAdd from '@/views/FinanceAdd.vue';
import RegisterForm from '@/views/RegisterForm.vue';
import RegisterFormElections from '@/views/RegisterFormElections.vue';
import ThankYou from '@/views/ThankYou.vue';

const routes = [
    {
        path: '/',
        name: 'index',
        redirect: to => {
            return 'dashboard'
        }
    },
    {
        path: '/login',
        name: 'login',
        beforeEnter(to, from, next) {
            var returnUri = CONFIG.vueAppUrl + "/oauth/return"
            var path = to.query.path;
            if (path) {
                returnUri += "?path=" + path;
            }
            window.location.href = CONFIG.api.baseUrl + "/auth/token?redirect_uri=" + encodeURIComponent(returnUri);
        }
    },
    {
        path: '/logout',
        name: 'logout',
        beforeEnter(to, from, next) {
            store.commit("removeToken");
            var returnUri = CONFIG.vueAppUrl + "/oauth/return"
            window.location.href = CONFIG.api.baseUrl + "/auth/logout?redirect_uri=" + returnUri;
        }
    },
    {
        path: "/oauth/return",
        name: "oauth-return",
        async beforeEnter(to, from, next) {
            store.commit("setToken", to.query.jwt);
            var attempts = 0;
            var totalTime = 5000;
            var attemptTime = 50;
            while (attempts < totalTime/attemptTime) {
                if (store.getters.loaded) {
                    break;
                }
                await new Promise(r => setTimeout(r, attemptTime));
                attempts++;
            }
            if (totalTime / attemptTime >= attempts && !store.getters.loaded) {
                next("/logout");
                return
            }

            var redirect = "dashboard";
            var path = to.query.path;
            if (path) {
                next({ path: path });
            }
            else {
                next({ name: redirect });
            }
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: { title: ['Nástěnka'], before: [], beforePermission: [] },
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: { title: ['Nastavení'], before: [], beforePermission: [] },
    },
    {
        path: '/members',
        name: 'members',
        component: Members,
        meta: { title: ['Registr členů'], before: [], beforePermission: [] },
        beforeEnter(to, from, next) {
            if (!store.getters.hasPerm("view_person")) {
                return next({ name: 'dashboard'});
            }
            return next()
        }
    },
    {
        path: '/member/:id',
        name: 'member-detail',
        component: Member,
        meta: { title: ['Registr členů', 'detail člena'], before: ['members'], beforePermission: ['view_person'] },
        beforeEnter(to, from, next) {
            if (!store.getters.hasPerm("view_person") && store.getters.user.id != to.params.id) {
                return next({ name: 'dashboard'});
            }
            return next()
        }
    },
    {
        path: '/member/profile',
        name: 'member-profile',
        component: Member,
        meta: { title: ['Registr členů', 'detail člena'], before: ['members'], beforePermission: ['view_person'] },
        beforeEnter(to, from, next) {
            return next({ name: 'member-detail', params: {id: store.getters.user.id}});
        }
    },
    {
        path: '/finances',
        name: 'finances',
        component: Finances,
        meta: { title: ['Finance'], before: [], beforePermission: [] },
        beforeEnter(to, from, next) {
            if (!store.getters.hasPerm("view_finances")) {
                return next({ name: 'dashboard'});
            }
            return next()
        }
    },
    {
        path: '/finance/:id',
        name: 'finance-detail',
        component: FinanceDetail,
        meta: { title: ['Finance', 'detail dokladu'], before: ['finances'], beforePermission: ['view_finances'] },
        beforeEnter(to, from, next) {
            if (!store.getters.hasPerm("view_finances")) {
                return next({ name: 'dashboard'});
            }
            return next()
        }
    },
    {
        path: '/finance/add',
        name: 'finance-add',
        component: FinanceAdd,
        meta: { title: ['Finance', 'nový doklad'], before: ['finances'], beforePermission: ['view_finances'] },
        beforeEnter(to, from, next) {
            if (!store.getters.hasPerm("add_finances")) {
                return next({ name: 'dashboard'});
            }
            return next()
        }
    },
    {
        path: '/sign_up',
        name: 'sign_up',
        component: RegisterForm,
        meta: { title: ['Registrace'], before: [], beforePermission: [] }
    },
    {
        path: '/sign_up_elections',
        name: 'sign_up_elections',
        component: RegisterFormElections,
        meta: { title: ['Registrace do okrskové volební komise'], before: [], beforePermission: [] }
    },
    {
        path: '/thankyou',
        name: 'thank-you',
        component: ThankYou,
        meta: { title: ['Registrace dokončena'], before: [], beforePermission: [] }
    },
]

const router = createRouter({
    base: import.meta.env.VITE_BASE_URL,
    history: createWebHistory(import.meta.env.VITE_BASE_URL),
    routes: routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ['login', 'logout', 'sign_up', 'oauth-return', 'sign_up_elections', 'thank-you']
    if (store.getters.isLoggedIn || publicPages.includes(to.name)) {
        if (store.getters.isLoggedIn) {
            store.commit("reloadUser");
        }
        next();
    } else {
        next({ name: 'login', query: { path: to.path } });
    }
})

export default router;
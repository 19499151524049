<template>
  <div class="row align-items-end pb-3">
    <div class="col-12 col-md-4 col-lg-12 col-xxl-4 avatar">
      <img :src="memberAvatarUrl" class="img-fluid rounded" alt="Avatar člena">
      <button class="btn btn-secondary photo-icon" data-bs-toggle="modal" data-bs-target="#uploadPhoto" v-if="canUpload">
        <icon icon="Edit" />
      </button>
    </div>
    <div class="col-12 col-md-8 col-lg-12 col-xxl-8 mt-2 d-inline-flex justify-content-end">

      <button v-if="canInterview" class="btn btn-primary text-nowrap" @click="interviewDone">
        <icon icon="Edit" />
        &nbsp;Pohovor absolvován
      </button>

      <button v-if="canAccept" class="btn btn-primary ms-2 text-nowrap" data-bs-target="#acceptMember" data-bs-toggle="modal">
        <icon icon="Edit" />
        &nbsp;Schválení
      </button>

      <button v-if="editable" class="btn btn-secondary text-nowrap me-2" @click="editData(false)">
        Zavřít bez uložení
      </button>

      <button v-if="canEdit" class="btn btn-primary ms-2 text-nowrap" @click="editData(true)">
        <icon icon="Edit" />
        &nbsp;{{ editable ? "Uložit změny" : "Editovat údaje" }}
      </button>
      <button v-else-if="this.$store.getters.user.id == this.$route.params.id && !editable" class="btn btn-primary ms-2 text-nowrap"
        data-bs-toggle="modal" data-bs-target="#editDetails" @click="editData(true)">
        <icon icon="Edit" />
        &nbsp;Editovat údaje
      </button>
      <button v-else-if="this.$store.getters.user.id == this.$route.params.id && editable" class="btn btn-primary ms-2 text-nowrap"
        @click="editData(true)">
        <icon icon="Edit" />
        &nbsp;Uložit změny
      </button>

      <button class="btn btn-secondary dropdown ms-2 text-nowrap" type="button" id="cv" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" v-if="member.cv || canUpload">
        <icon icon="ChevronDownWhite"/>
        &nbsp;Životopis
      </button>
      <div class="dropdown-menu" aria-labelledby="cv" v-if="member.cv || canUpload">
        <button class="dropdown-item" @click="downloadCV" v-if="member.cv">Stáhnout PDF</button>
        <button class="dropdown-item" data-bs-toggle="modal" data-bs-target="#uploadCV" v-if="canUpload">Nahrát PDF</button>
        <button class="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteCV" v-if="member.cv">Smazat</button>
      </div>
      <button class="btn btn-secondary dropdown ms-2 text-nowrap" type="button" id="payments" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <icon icon="ChevronDownWhite" />
        &nbsp;Platby
      </button>
      <div class="dropdown-menu" aria-labelledby="payments">
        <button class="dropdown-item" data-bs-toggle="modal" data-bs-target="#payment" v-if="this.$store.getters.hasPerm('set_payment')">Vyznačit platbu</button>
        <button class="dropdown-item" @click="financeRedirect">Přehled plateb</button>
      </div>
    </div>
    <Popup :header="'Změna osobních údajů'" id="editDetails" :button="'Rozumím'">
      <template v-slot:body>
        <div class="mb-3">
          <p>Můžete editovat text pro kandidaturu, telefon, email, odběr newsletteru a sdílení.</p>
          <p>Pro změnu osobních údajů odešlete žádost do kanceláře: kancelar@svobodni.cz</p>
        </div>
      </template>
    </Popup>
    <Popup :header="'Vyznačit platbu'" :editable="true" id="payment" :button="'Nastavit'" @save-data="setPayment" v-if="this.$store.getters.hasPerm('set_payment')">
      <template v-slot:editor>
        <div class="mb-3">
          <label for="date" class="m-2">Nastavit úhradu členského poplatku do:</label>
          <input type="date" id="date" class="form-control" v-model="date">
        </div>
      </template>
    </Popup>
    <Popup :header="'Nahrát životopis'" :editable="true" id="uploadCV" @save-data="uploadCVToServer">
      <template v-slot:editor>
        <input type="file" accept=".pdf" @change="changeCV">
      </template>
    </Popup>
    <Popup :header="'Smazat životopis'" :body="'Opravdu si přejete smazat životopis?'" id="deleteCV" :button="'Smazat'" @save-data="deleteCV"/>
    <Popup :header="'Nahrát profilovou fotografii'" :editable="true" id="uploadPhoto" @save-data="uploadPhotoToServer">
      <template v-slot:editor>
        <input type="file" accept="image/*" @change="changePhoto">
      </template>
    </Popup>
    <Popup :header="'Schválení nového člena'" id="acceptMember">
      <template v-slot:body>
        <div>
          <button class="btn btn-primary text-nowrap" @click="memberAccept(true)" data-bs-dismiss="modal">Schválen</button>
          <button class="btn btn-primary ms-2 text-nowrap" @click="memberAccept(false)" data-bs-dismiss="modal">Neschválen</button>
        </div>
      </template>
    </Popup>

  </div>
</template>


<script>
import Popup from "@/components/Popup.vue";

export default {
  components: {
    Popup
  },
  emits: ['reload-data', 'edit-data'],
  data() {
    return {
      CV: null,
      Photo: null,
      hrefPhoto: null,
      date: "",
    }
  },
  created: function () {
    const today = new Date();
    this.date = `${today.getFullYear()}-12-31`;
  },
  props: {
    editable: {
      type: Boolean,
      required: true
    },
    memberId: {
      required: true
    },
    member: {
      type: Object,
      required: true
    },
  },
  computed: {
    canEdit: function () {
      return this.$store.getters.hasPerm('change_person');
    },
    canUpload: function () {
      return this.$store.getters.user.id == this.$route.params.id
    },
    canInterview: function () {
      if (this.$store.getters.hasPerm('manage_all_regions_members')) {
        return this.$store.getters.hasPerm('can_interview') && this.member.to_interview;
      }
      if (this.$store.getters.user.krp_region_id && this.member && this.member.domestic_region && this.member.domestic_region.id) {
        return (this.$store.getters.user.krp_region_id === this.member.domestic_region.id)
          && this.$store.getters.hasPerm('can_interview') && this.member.to_interview;;
      }
      return false;
    },
    canAccept: function () {
      if (this.$store.getters.hasPerm('manage_all_regions_members')) {
        return this.$store.getters.hasPerm('can_accept_as_member') && this.member.to_accept;
      }
      if (this.$store.getters.user.krp_region_id && this.member && this.member.domestic_region && this.member.domestic_region.id) {
        return (this.$store.getters.user.krp_region_id === this.member.domestic_region.id)
          && this.$store.getters.hasPerm('can_accept_as_member') && this.member.to_accept;;
      }
      return false;
    },
    memberAvatarUrl: function () {
      if (this.member && this.member.avatar && this.hrefPhoto) {
        return this.hrefPhoto;
      }
      return "https://via.placeholder.com/160x160";
    },
  },
  methods: {
    editData(save) {
      this.$emit('edit-data', save);
    },
    setPayment: function () {
      this.$store.getters.api.put("/member/" + this.memberId + "/payment", { date: this.date }).then(function (response) {
        this.$emit('reload-data'); // Reload data to display changes
      }.bind(this))
      .catch(function (error) {
        alert("Nepodařilo se nastavit platbu. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
      });
    },
    uploadPhotoToServer: function () {
      let formData = new FormData();
      formData.append('avatar', this.Photo);
      this.$store.getters.api.post('/member/' + this.memberId + "/avatar",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function () {
        this.member.avatar = true;
        this.downloadPhoto();
      }.bind(this));
    },
    uploadCVToServer: function () {
      let formData = new FormData();
      formData.append('cv', this.CV);
      this.$store.getters.api.post('/member/' + this.memberId + "/cv",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function () {
        this.member.cv = true;
      }.bind(this));
    },
    deleteCV: function () {
      this.$store.getters.api.delete('/member/' + this.memberId + "/cv"
      ).then(function () {
        this.member.cv = false;
      }.bind(this));
    },
    downloadCV: function () {
      this.$store.getters.api.get('/member/' + this.memberId + "/cv",
        {
          responseType: 'blob'
        }).then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', this.member.name + '.pdf'); //create name
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    downloadPhoto: function () {
      this.$store.getters.api.get('/member/' + this.memberId + "/avatar",
        {
          responseType: 'blob'
        }).then((response) => {
          // create file link in browser's memory
          this.hrefPhoto = URL.createObjectURL(response.data);
        });
    },
    changeCV(event) {
      this.CV = event.target.files[0];
    },
    changePhoto(event) {
      this.Photo = event.target.files[0];
    },
    interviewDone: function () {
      this.$store.getters.api.post('/member/' + this.memberId + "/interviewed").then(function () {
        this.member.to_interview = false
      }.bind(this)
      ).catch(
        function (error) {
          alert("Něco se pokazilo: Operace pohovoru nedopadla dobře. Kontaktujte správce systému.")
        }
      )
    },
    memberAccept: function (accept) {
      this.$store.getters.api.post('/member/' + this.memberId + "/accept", {accept: accept}).then(function () {
        this.member.to_accept = false
      }.bind(this)
      ).catch(
        function (error) {
          alert("Něco se pokazilo: Operace schválení nedopadla dobře. Kontaktujte správce systému.")
        }
      )
    },
    financeRedirect: function () {
      // add finance filters
      this.$store.commit("resetFiltersFinance");
      this.$store.commit("setFiltersFinance", { key: "search", value: this.member.id.toString() });
      const types = [];
      this.$store.getters.docTypes.forEach((docType) => {
        if (docType.name == "Členské poplatky" || docType.name == "Dary") {
          types.push(docType.id);
        }
      });
      this.$store.commit("setFiltersFinance", { key: "document_type", value: types });
      this.$router.push({ name: 'finances' });
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

.avatar {
  max-height: 160px;
  position: relative;
}

.avatar img {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.photo-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(32%, -10%);
  -ms-transform: translate(32%, -10%);
  -webkit-transform: translate(32%, -10%);
  opacity: 0.4;
}

.photo-icon:hover {
  opacity: 1;
}
</style>
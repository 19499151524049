<template>
  <div class="row p-4">
    <div class="col-12 col-xl-7 px-4">
      <DocumentHeader :document="data" :documentId="documentId" :editable="editable" :bankAccounts="bankAccounts" @edit-data="editData" @load-data="loadData"/>
      <DocumentDetail :document="data" :editable="editable" :bankAccounts="bankAccounts" @update-document="handleDocUpdate" />
      <DocumentBudgets :documentId="documentId" v-if="$store.getters.hasPerm('view_budget')" ref="budgets"/>
      <DocumentNotes :documentId="documentId" v-if="$store.getters.hasPerm('view_notefinances')" ref="notes"/>
      <DocumentDonor :documentId="documentId" v-if="$store.getters.hasPerm('view_donor')" ref="donor"/>
    </div>
    <div class="col-12 col-xl-5 px-4">
      <div class="justify-content-between d-flex">
        <h2>Přílohy</h2>
        <button type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#addFile"
          @click="resetMessage()"
          v-if="$store.getters.hasPerm('view_documents')">
          <icon icon="Plus" />
        </button>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <div>
          <button
            v-for="index in data.document_count"
            v-key="index"
            class="btn my-2 me-2"
            :class="{'btn-outline-secondary': index != documentIndex, 'btn-primary': index == documentIndex}"
            @click="changeDocumentIndex(index)">
            {{ index }}
          </button>
        </div>
        <div v-if="data.document_count">
          <button
            type="button"
            class="btn my-2 ms-2 btn-primary"
            @click="downloadDoc('all')">
            Stáhnout vše
          </button>
          <button
            type="button"
            class="btn my-2 ms-2 btn-primary"
            @click="downloadDoc(documentIndex)">
            Stáhnout
          </button>
          <button
            type="button"
            class="btn my-2 ms-2 btn-danger"
            data-bs-toggle="modal"
            data-bs-target="#deleteCurrentFile">
            Smazat
          </button>
        </div>
      </div>
      <embed v-if="$store.getters.hasPerm('view_documents') && documentPreview && previewType" :src="documentPreview" :type="previewType" class="preview">
    </div>
    <Popup
      :header="'Smazat soubor'"
      :editable="false"
      id="deleteCurrentFile"
      :body="'Opravdu chcete soubor smazat.'"
      :button="'Smazat'"
      @save-data="deleteActualDocument"
      v-if="$store.getters.hasPerm('view_documents')"/>
    <Popup
      :header="'Nahrát soubor/y'"
      :editable="true"
      id="addFile"
      :button="'Uložit'"
      @save-data="uploadDocToServer"
      v-if="$store.getters.hasPerm('view_documents')">
      <template v-slot:editor>
        <input type="file" accept=".pdf, .jpg, .png, .gif" multiple @change="handleFileChanged">
      </template>
    </Popup>
  </div>
</template>


<script>
import DocumentHeader from "@/components/DocumentHeader.vue";
import DocumentDetail from "@/components/DocumentDetail.vue";
import DocumentBudgets from "@/components/DocumentBudgets.vue";
import DocumentNotes from "@/components/DocumentNotes.vue";
import DocumentDonor from "@/components/DocumentDonor.vue";
import Popup from '@/components/Popup.vue';

export default {
  components: {
    DocumentHeader,
    DocumentDetail,
    DocumentBudgets,
    DocumentNotes,
    DocumentDonor,
    Popup
  },
  data() {
    return {
      documentId: null,
      editable: false,
      data: {},
      documentFiles: null,
      documentPreview: '',
      previewType: '',
      bankAccounts: [],
      bankAccountsOther: [],
      documentIndex: 1
    }
  },
  watch: {
    "$route.params.id": {
      handler: function (val) {
        if (val) {
          this.documentId = this.$route.params.id;
          this.loadData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeDocumentIndex: function (i) {
      this.documentIndex = i;
      this.previewDoc();
    },
    handleDocUpdate({ where, property, newValue }) {
      switch (where) {
        case 'document_type':
          this.data.document_type[property] = newValue;
          break;
        case 'region':
          this.data.region[property] = newValue;
          break;
        case 'chapter':
          this.data.chapter[property] = newValue;
          break;
        case 'subchapter':
          this.data.subchapter[property] = newValue;
          break;
        case 'pay_to':
          this.data.pay_to[property] = newValue;
          break;
        case 'pay_from':
          this.data.payment_error = true; // We need to keep the document editable until it is saved, if it was already approved
          this.data.pay_from[property] = newValue;
          break;
        default:
          this.data[property] = newValue;
      }
    },
    editData(save) {
      if (this.editable && save === true) {
        if (Object.keys(this.data.document_type).length === 0
            || Object.keys(this.data.region).length === 0
            || !this.data.description
            || !this.data.amount
            || !((this.data.pay_to.account_number && this.data.pay_to.bank_number) || this.data.pay_to.iban)) {
          alert("Musíte zadat povinné údaje: typ dokladu, organizace, org. složka, popis plnění, částka, číslo účtu");
          return;
        }
        const numberPattern = /^\d*$/; // regex pattern for numbers only
        if ((this.data.vs && !numberPattern.test(this.data.vs))
            || (this.data.ks && !numberPattern.test(this.data.ks))
            || (this.data.ss && !numberPattern.test(this.data.ss))) {
          alert('VS, KS a SS mohou obsahovat pouze číselnou hodnotu');
          return
        }
        this.$store.getters.api.put("/finance/" + this.documentId, this.data)
          .then(function () {
            this.editable = false;
            this.data.payment_error = false;
          }.bind(this))
          .catch(function (error) {
            alert("Nepodařilo se uložit změny. Opakujte akci, nebo kontaktujte správce systému. Chyba: " + error.message);
          });
      } else if (this.editable) {
        this.editable = false;
        this.loadData();
      } else {
        this.editable = true;
      }
    },
    loadData: function () {
      this.$store.getters.api.get("/finance/accounts").then(response => {
        this.bankAccounts = [];
        const sorted = response.data.items.sort((a, b) => {
            if (a.name === null && b.name === null) {
                return 0;
            } else if (a.name === null) {
                return -1;
            } else if (b.name === null) {
                return 1;
            } else {
                return a.name.localeCompare(b.name);
            }
        }); // Sort accounts by name
        sorted.forEach((account) => {
          if (account.account_type === 'intern') {
            this.bankAccounts.push(account);
          } else {
            this.bankAccountsOther.push(account);
          }
        })
      });
      this.$store.getters.api.get("/finance/" + this.documentId).then(function (response) {
        this.data = response.data.items;
        if (this.data.document_count) {
          this.previewDoc();
        }
      }.bind(this));

      if (this.$refs && this.$refs.notes) {
        this.$refs.notes.loadData();
      }
      if (this.$refs && this.$refs.budgets) {
        this.$refs.budgets.loadData();
      }
    },
    handleFileChanged(event) {
      this.documentFiles = event.target.files;
    },
    uploadDocToServer: function () {
      console.log(this.documentFiles)
      Array.from(this.documentFiles).forEach(function (file) {
        let formData = new FormData();
        formData.append('document', file);
        this.$store.getters.api.post("/finance/" + this.data.id + "/document",
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function () {
        }.bind(this));
      }.bind(this));
      setTimeout(function () {
        this.loadData();
      }.bind(this), 500);
    },
    deleteActualDocument: function () {
      if (!this.$store.getters.hasPerm('view_documents')) {
        return;
      }
      this.$store.getters.api.delete("/finance/" + this.documentId + "/document/" + this.documentIndex, {
        responseType: 'blob'
      }).then((response) => { this.loadData() });
    },
    downloadDoc: function (item) {
      if (!this.$store.getters.hasPerm('view_documents')) {
        return;
      }

      this.$store.getters.api.get("/finance/" + this.documentId + "/document/" + item,
        {
          responseType: 'blob'
        }).then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // determine file extension from Content-Type header
          const contentType = response.headers['content-type'];
          let fileExtension = '';
          switch (contentType) {
            case 'application/pdf':
              fileExtension = '.pdf';
              break;
            case 'image/jpeg':
              fileExtension = '.jpg';
              break;
            case 'image/png':
              fileExtension = '.png';
              break;
            case 'image/gif':
              fileExtension = '.gif';
              break;
            case 'application/zip':
              fileExtension = '.zip';
              break;
            default:
              console.error('Unsupported content type:', contentType);
              return;
          }

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', this.documentId + (item == "all"? "": "-" + item) + fileExtension); //create name
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }).catch((error) => {
          console.error('Error downloading file:', error);
        });
    },
    previewDoc: function () {
      if (!this.$store.getters.hasPerm('view_documents')) {
        return;
      }

      this.$store.getters.api.get("/finance/" + this.documentId + "/document/" + this.documentIndex, {
        responseType: 'blob'
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // determine file extension from Content-Type header
        const contentType = response.headers['content-type'];
        this.previewType = contentType;

        // update documentPreview with the URL
        this.documentPreview = href;
      }).catch((error) => {
        console.error('Error downloading file:', error);
      });
    },
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

.preview {
  border: 1px solid grey;
  width: 100%;
  height: 85vh;
  object-fit: contain;
}

</style>
